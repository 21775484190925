import React, { Component } from 'react';

class Welcome extends Component {
	render() {
		return ( 
			<div className="Welcome">
  				<div className="background-image pimg2"></div>
				<div className="welcome-text">
					<p>Use the search function below to find products based on ingredients you either DO or DO NOT want in your haircare regimen.</p>
				</div>
			</div>
		);
	}
}

export default Welcome;