import React, { Component } from 'react';

export class ListItem extends Component {


	render() {

		const { id, title, type } = this.props.ListItem;

		return (

			<div className="dropdown-item"> {/* replaced a and took out href="3" */}
				<label>
					<input
						type="checkbox"
						checked={this.props.ListItem.selected}
						onChange={this.props.markSelected.bind(this, id, type)} /> {' '}
						{title}
				</label>
			</div>
		);
	}
}


export default ListItem;
