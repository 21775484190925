import React, { Component } from 'react';

import facebook from '../img/icons/icons/facebook.png'
import instagram from '../img/icons/icons/instagram.png'
import email from '../img/icons/icons/email.png'
import youtube from '../img/icons/icons/youtube.png'


class Contact extends Component {
	render() {
		return (
			<div className="Contact">

  				<section className="section-white">

					<div className="container-fluid">

						<div className="row">

							<div className="col">
								<h3>Connect</h3>
								<ul>
									<li>
										<a href="mailto: amanda@uptightcurls.com">
											<img src={email} alt="email" /> amanda@uptightcurls.com
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/uptightcurls_by_amanda/" target="_blank" rel="noopener noreferrer">
											<img src={instagram} alt="instagram" /> @uptightcurls_by_amanda
										</a>
									</li>
									<li>
										<a href="https://www.facebook.com/UptightCurlsbyAmanda" target="_blank" rel="noopener noreferrer">
											<img src={facebook} alt="instagram" /> Uptight Curls By Amanda
										</a>
									</li>
									<li>
										<a href="https://www.youtube.com/c/uptightcurlsbyamanda" target="_blank" rel="noopener noreferrer">
											<img src={youtube} alt="youtube" /> Uptight Curls By Amanda
										</a>
									</li>
								</ul>
							</div>
							<div className="col">
								<h3>Business Inquiries</h3>
								<div className="inquiries">
									📧 Contact <a href="mailto: UptightCurls@gmail.com">UptightCurls@gmail.com </a> to add your products to site inventory, and for any partner or sponsorship inquiries.
								</div>
								<hr />
								<h5>Product Disclaimer</h5>
								<div>All products presented on this site are made without the following harmful ingredients - Phthalates, Petroleum, Mineral oils, Parabens or Sulfates.</div>
							</div>
						</div>
					</div>
  				</section>
			</div>
		);
	}
}

export default Contact;
