import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return ( 
			<div className="Footer">
  				<footer>
					<p>Disclaimer: This website has collated public information from brand websites. Ingredients have not been vetted and may be incorrect. Further, any related youtube videos reflect my personal opinions on the products used.</p>
					<hr />
					&copy; Uptight Curls by Amanda | Designed by <a href="mailto: kev1nl3gall@gmail.com">Kevin LeGall</a>
  				</footer>
			</div>
		);
	}
}

export default Footer;