import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Welcome from './components/Welcome';
import Search from './components/Search';
import Results from './components/Results';
import Contact from './components/Contact';
import Footer from './components/Footer';
import axios from 'axios';

import './App.css';
import './bootstrap.min.css'


class App extends Component {

    state = {
        productTypes: [],
        includes: [],
        excludes: [],
        preservatives: [],
        brands: [],
        brand: [],
        kids: [],
        selectedItems: [],
        isResults: false,
        result: []
    }

    isResults() {
        return this.state.isResults
    }

    componentDidMount() {

        axios('feed.json')
            .then(res => this.setState({productTypes: res.data.productTypes}))
            .catch(error => console.log('Error: ', error))

        axios('feed.json')
            .then(res => this.setState({excludes: res.data.excludes}))
            .catch(error => console.log('Error: ', error))

        axios('feed.json')
            .then(res => this.setState({includes: res.data.includes}))
            .catch(error => console.log('Error: ', error))

        axios('feed.json')
            .then(res => this.setState({preservatives: res.data.preservatives}))
            .catch(error => console.log('Error: ', error))

        axios('feed.json')
          .then(res => this.setState({brand: res.data.brand}))
          .catch(error => console.log('Error: ', error))


        axios('brands.json')
            .then(res => this.setState({brands: res.data}))
            .catch(error => console.log('Error: ', error))
    }


    markSelected = (id, type) => {

        switch(type) {
            case 'productType':
            let list = this.state.productTypes;
                this.setState({
                    productTypes: this.state.productTypes.map(product => {
                        if(product.id === id) {
                            product.selected = !product.selected

                            list.forEach(item => {
                              if(item.id === 99 && id !== 99){
                                item.selected = false
                            }})


                        }
                        if(product.id === id && product.id === 99){
                          list.forEach(item => {
                            item.selected = product.selected
                          })

                        }
                        return product;
                    })
                });
                break;

            case 'excludes':
                this.setState({
                    excludes: this.state.excludes.map(excludes => {
                        if(excludes.id === id) {
                            excludes.selected = !excludes.selected
                        }
                        return excludes;
                    })
                });
                break;

            case 'includes':
                this.setState({
                    includes: this.state.includes.map(includes => {
                        if(includes.id === id) {
                            includes.selected = !includes.selected
                        }
                         return includes;
                    })
                });
                break;

          case 'preservatives':
                this.setState({
                    preservatives: this.state.preservatives.map(preservatives => {
                        if(preservatives.id === id) {
                            preservatives.selected = !preservatives.selected
                        }
                         return preservatives;
                    })
                });
                break;
          case 'brand':
                this.setState({
                    brand: this.state.brand.map(brand => {
                        if(brand.id === id) {
                            brand.selected = !brand.selected
                        }
                      //  alert(brand)
                         return brand;
                    })
                });
                break;

        break;
                      break;

            default:
                break;
        }
    }


    searchItems = () => {
        var selectedItems = []

        selectedItems['productTypes'] = this.state.productTypes.filter(productType => productType.selected)
        selectedItems['excludes'] = this.state.excludes.filter(exclude => exclude.selected)
        selectedItems['includes'] = this.state.includes.filter(include => include.selected)
        selectedItems['preservatives'] = this.state.preservatives.filter(preservatives => preservatives.selected)
        selectedItems['brand'] = this.state.brand.filter(brand => brand.selected)


        //if(selectedItems['productTypes'].length === 0 || selectedItems['excludes'].length === 0 || selectedItems['includes'].length === 0) {
        if(selectedItems['productTypes'].length === 0 || (selectedItems['preservatives'].length === 0 && selectedItems['excludes'].length === 0 && selectedItems['includes'].length === 0 && selectedItems['brand'].length === 0)) {
            alert('Please select at least one PRODUCT TYPE and one FILTER from the dropdowns')
            this.setState({ isResults: false })
        } else {
            this.setState({ selectedItems: selectedItems })
            this.setState({ isResults: true })
        }
    }

    render() {

        return (
            <Router>
                <div className="App">

                    <Menu />

                    <Route exact path="/" render={props => (
                        <React.Fragment>
                            <Welcome />

                            <Search Search={this.state}
                                markSelected={this.markSelected}
                                searchItems={this.searchItems} />

                            { this.state.isResults && <Results Results={this.state} /> }


                            <Contact />

                            <Footer />
                        </React.Fragment>

                    )} />

                </div>
            </Router>
        );
    };
}

export default App;
