import React, { Component } from 'react';
import youtube from '../img/icons/social/youtube.png'

export class TableItem extends Component {


	render() {
        const { brand, product, ingredients, link, aff, comm, type } = this.props.TableItem;
        var youtubeHTML = <td></td>
				var affLink = <td></td>
				var commLink = <td></td>

        if(link) {
            youtubeHTML =   <td>
                                <a href={link}>
                                    <img src={youtube} alt="youtube" />
                                </a>
                            </td>
        }
				if(comm) {
						commLink =   <td>
																<a href={comm}>
																		<img src={youtube} alt="youtube" />
																</a>
														</td>
				}
				if(aff) {
					affLink = <td><a href={aff} rel="noreferrer" target="_blank">Go!</a></td>
				}


		return (

            <tr className="table-item text-left text-capitalize">

                <td>{brand}</td>
                <td>{product}</td>
                <td>{type}</td>
                <td>{ingredients.join(', ')}</td>
                {youtubeHTML}
								{commLink}
								{affLink}
            </tr>
		);
	}
}


export default TableItem;
