import React, { Component } from 'react';

import facebook from '../img/icons/social/facebook.png'
import instagram from '../img/icons/social/instagram.png'
import youtube from '../img/icons/social/youtube.png'
import menu from '../img/icons/icons/homeicon.png'

class Menu extends Component {
	render() {
		return (
			<div className="Menu">
				<header>
					<div class="menu">
						<a href="http://www.uptightcurls.com">
							<img src={menu} alt="menu" />
						</a>
					</div>
					<ul>
						<li>
							<a href="https://www.youtube.com/c/uptightcurlsbyamanda" target="_blank" rel="noopener noreferrer">
								<img src={youtube} alt="youtube" />
							</a>
						</li>
						<li>
							<a href="https://www.instagram.com/uptightcurls_by_amanda/" target="_blank" rel="noopener noreferrer">
								<img src={instagram} alt="instagram" />
							</a>
						</li>
						<li>
							<a href="https://www.facebook.com/UptightCurlsbyAmanda" target="_blank" rel="noopener noreferrer">
								<img src={facebook} alt="facebook" />
							</a>
						</li>
					</ul>
				</header>
			</div>
		);
	}
}

export default Menu;
