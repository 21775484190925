import React, { Component } from 'react';
import TableItem from './TableItem';

class Table extends Component {

	render() {
		var i = 0;
		return this.props.Table.map((Table) => (
			 <TableItem key={i++} TableItem={Table} />
		));
		
	}
}

export default Table;