import React, { Component } from 'react';
import List from './List';

class Search extends Component {

	markSelected = (e) => { }

	render() {

		return (
			<div className="Search">

				<section className="section-dark">

					<h1>SEARCH THE BEST PRODUCTS FOR <span>YOUR</span> HAIR!</h1>

					<div className="container-fluid">
						<br /><br />
						<div className="row">

							<div className="col-sm">

								<h5>Include these products</h5>
								<h6>(select all that apply)</h6>
								<div className="dropdown">

									<button className="btn btn-secondary dropdown-toggle sized" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Product Type</button>

									<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<List List={this.props.Search.productTypes} markSelected={this.props.markSelected} />
									</div>
								</div>
							</div>

							<br />

							<div className="col-sm">

								<h5>Include these ingredients</h5>
								<h6>(select all that apply)</h6>
								<div className="dropdown">

					              	<button className="btn btn-secondary dropdown-toggle sized" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Likes</button>

									<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<List List={this.props.Search.includes} markSelected={this.props.markSelected} />
									</div>
								</div>
							</div>

							<br />

							<div className="col-sm">

								<h5>Exclude these ingredients</h5>
								<h6>(select all that apply)</h6>
								<div className="dropdown">

									<button className="btn btn-secondary dropdown-toggle sized" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dislikes</button>

									<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<List List={this.props.Search.excludes} markSelected={this.props.markSelected} />
									</div>
					      </div>
					    </div>

							<br /><br /><br />

							<div className="col-sm">

								<h5>Exclude these preservatives</h5>
								<h6>(select all that apply)</h6>
								<div className="dropdown">

									<button className="btn btn-secondary dropdown-toggle sized" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Preservatives</button>

									<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<List List={this.props.Search.preservatives} markSelected={this.props.markSelected} />
									</div>
								</div>
							</div>
							</div>
							<div className="container-fluid">
								<div className="row">
									<div className="col-sm">
										<br/>
										<h5>Filter by brand</h5>
										<h6>(optional)</h6>
											<div className="dropdown">

											<button className="btn btn-secondary dropdown-toggle sized" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Brands</button>

												<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<List List={this.props.Search.brand} markSelected={this.props.markSelected} />
												</div>
										 </div>
								 </div>
						</div>
						</div>
						<div className="container-fluid">
							<div className="row">
								<div className="col-sm">
        					<br/><br/>
									<h5>Show me kid's products</h5>
									<div className="checkbox">
									<input type="checkbox" id ="kid_check" name="kid_check" value="false"/>

									</div>

							  </div>
							</div>
						</div>




						<br />
          				<br />

						<button
							type="button"
							onClick={this.props.searchItems}
							className="btn btn-primary btn-lg">
							Find My Product(s)!
						</button>
					</div>
				</section>
			</div>
		);
	}
}


export default Search;
