import React, { Component } from 'react';
import Table from './Table';
import laila from '../img/laila2.jpg'

class Results extends Component {

	isTable() {
        return this.state.results
    }

	render() {

		var { brands } = this.props.Results
		const { selectedItems } = this.props.Results
		var results = [], table = '',kid_img='',kid_desc='', ctr

		// Parse list of ProductTypes to Array from Object
		const productResults = selectedItems['productTypes'].filter(product => (product.selected !== false && product.id !== 99))
		const productTypesList = productResults.map((product, index) => { return(product.title); })

		// Parse list of Excludes to Array from Object
		const excludesResults = selectedItems['excludes'].filter(exclude => exclude.selected !== false)
		const excludesList = excludesResults.map((exclude, index) => { return( exclude.title ); })
		// Parse list of Includes to Array from Object
		const includesResults = selectedItems['includes'].filter(include => include.selected !== false)
		const includesList = includesResults.map((include, index) => { return( include.title ); })
		// Parse list of Preservatives to Array from Object
		const exPreserveResults = selectedItems['preservatives'].filter(pres => pres.selected !== false)
		const preservativesList = exPreserveResults.map((pres, index) => { return( pres.title ); })
		// Parse list of Brands to Array from Object
		const brandsResults = selectedItems['brand'].filter(brand_inc => brand_inc.selected !== false)
		const brandsList = brandsResults.map((brand_inc, index) => { return( brand_inc.title ); })

		const cb = document.getElementById('kid_check');

		// Filter for Brands that include ProductTypes
		Object.values(brands).forEach(function(brand){
			productTypesList.forEach(function(product) {
				if(product.toLowerCase() === brand.type.toLowerCase()) {
					results.push(brand)
				}
			})
		})

		brands = results.slice()
		results = []


		// console.log('1) brands: ', brands)

		// Filter out Brands that include Excludes
		if(excludesList && excludesList.length) {
			Object.values(brands).forEach(function(brand){
				ctr = 0
				excludesList.forEach(function(exclude) {
					if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
						if(!brand.ingredients.some(i => i === exclude.toLowerCase() || i === exclude.toUpperCase() )) {
							ctr = ctr + 1;
							if(ctr === excludesList.length){
								results.push(brand)
							}
							//alert(excludesList)

						}
					}
				})
			})
		} else {
			Object.values(brands).forEach(function(brand){
				if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
					//alert(excludesList)
					results.push(brand)
				}
			})
		}

		brands = results.slice()
		results = []

		if(preservativesList && preservativesList.length) {
			Object.values(brands).forEach(function(brand){
				ctr = 0
				preservativesList.forEach(function(pres) {
					if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
						if(!brand.ingredients.some(i => i === pres.toLowerCase())) {
							ctr = ctr + 1;
							if(ctr === preservativesList.length){
								results.push(brand)
							}
							//alert(excludesList)

						}
					}
				})
			})
		} else {
			Object.values(brands).forEach(function(brand){
				if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
					//alert(excludesList)
					results.push(brand)
				}
			})
		}

		brands = results.slice()
		results = []
		// Filter list of Brands for items that match list of includes
		if(includesList && includesList.length) {
			Object.values(brands).forEach(function(brand){
				includesList.forEach(function(include) {
					if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
						if(brand.ingredients.some(i => i.toLowerCase() === include.toLowerCase())) {
							results.push(brand)
						}
					}
				})
			})
		} else {
			Object.values(brands).forEach(function(brand){
				if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
					results.push(brand)
				}
			})
		}
		brands = results.slice()
		results = []

		if(brandsList && brandsList.length) {
			Object.values(brands).forEach(function(brand){
				brandsList.forEach(function(brand_inc) {
		//			window.alert(brand.brand)
						if(brand.brand.toLowerCase() === brand_inc.toLowerCase()) {

							results.push(brand)
						}
				})
			})
		} else {
			Object.values(brands).forEach(function(brand){
				if(Array.isArray(brand.ingredients) && brand.ingredients.length) {
					//window.alert("we are here")
					results.push(brand)
				}
			})
		}
		brands = results.slice()
		results = []

		if(cb.checked){
			 Object.values(brands).forEach(function(brand){
				 //alert(brand.kids)
					if(brand.kids === true){
						results.push(brand)
					}
			})
		}
		else{
			Object.values(brands).forEach(function(brand){
				 if(brand.kids === false){
					 results.push(brand)
				 }
		 })
	 }


	 brands = results.slice()
	 results = []


		// Remove Duplicates
		brands.map(x => results.filter(a => a.product === x.product).length > 0 ? null : results.push(x));

		if(results.length <= 0) {
			table = <div class="col error">
						<p>No items from your search were found</p>
					</div>
		} else {
			if(cb.checked){
				 kid_img = <img src={laila} alt="notfound" width = "250" />
				// kid_img = <p/>
				 kid_desc = " Kid's "
				 
			}
			else{
				kid_img = <p/>
				kid_desc = ' '
			}
			table = <table className="table table-striped table-dark table-hover table-responsive">
						<thead>
							<tr className="text-left">
								<th scope="col">Brand</th>
								<th scope="col">Products</th>
								<th scope="col">Product Type</th>
								<th scope="col">Ingredients include</th>
								<th scope="col">Amanda's Review</th>
								<th scope="col">Community Review</th>
								<th scope="col">Shop Now</th>
							</tr>
						</thead>
						<tbody>
							<Table Table={results} />
						</tbody>
					</table>;
		}

		return (
			<div className="Results">

				<section className="section-purple">

					<h1>Your Search Returned <span>{results.length}</span>{kid_desc}Products</h1>

					<br />

					<p>They include: <span> { productTypesList.join(', ') }</span></p>
					<p>They don't contain: <span> { excludesList.join(', ') }</span></p>
					<p>or any of these preservatives: <span> { preservativesList.join(', ') }</span></p>
					<p>They do contain: <span> { includesList.join(', ') }</span></p>
					<p><span>{kid_img}</span></p>



					<br />

					<div className="container-fluid">

						<div className="row">

							{table}

						</div>

					</div>
				</section>
			</div>
		);
	}
}

export default Results;
