import React, { Component } from 'react';
import ListItem from './ListItem';

class List extends Component {

	render() {
		
		return this.props.List.map((List) => (
			 <ListItem key={List.id} ListItem={List} markSelected={this.props.markSelected} />
		));
		
	}
}

export default List;